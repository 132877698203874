import React from 'react'
import * as Styled from './LoadingOverlayStyles'
import { Spinner } from '@assets/svg/SpinnerIcon'

type LoadingOverlayType = {
    show: boolean
}

export const LoadingOverlay = ({ show }: LoadingOverlayType): JSX.Element => {
    return (
        <Styled.Backdrop open={show}>
            <Spinner />
        </Styled.Backdrop>
    )
}
