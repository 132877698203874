export const Spinner = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: 'auto' }}
            width="100"
            height="100"
            display="block"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 100 100"
        >
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
            >
                <animate
                    attributeName="opacity"
                    begin="-0.875s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
                transform="rotate(45 50 50)"
            >
                <animate
                    attributeName="opacity"
                    begin="-0.75s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
                transform="rotate(90 50 50)"
            >
                <animate
                    attributeName="opacity"
                    begin="-0.625s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
                transform="rotate(135 50 50)"
            >
                <animate
                    attributeName="opacity"
                    begin="-0.5s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
                transform="rotate(180 50 50)"
            >
                <animate
                    attributeName="opacity"
                    begin="-0.375s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
                transform="rotate(225 50 50)"
            >
                <animate
                    attributeName="opacity"
                    begin="-0.25s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
                transform="rotate(270 50 50)"
            >
                <animate
                    attributeName="opacity"
                    begin="-0.125s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
            <rect
                width="3"
                height="12"
                x="48.5"
                y="29"
                fill="#28292f"
                rx="1.5"
                ry="6"
                transform="rotate(315 50 50)"
            >
                <animate
                    attributeName="opacity"
                    begin="0s"
                    dur="1s"
                    keyTimes="0;1"
                    repeatCount="indefinite"
                    values="1;0"
                ></animate>
            </rect>
        </svg>
    )
}
